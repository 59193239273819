<template>
  <div class="app-main-container">
    <my-nav-bar
      title="付款明细"
      left-text="返回"
      left-arrow
    />
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="businessPayment in list" :key="businessPayment.id" class="item">
        <van-cell title="商家名称" :value="businessPayment.business.name" />
        <van-cell title="付款金额" :value="'￥' + businessPayment.pay_amount" value-class="cell-value" />
        <van-cell title="付款日期" :value="businessPayment.pay_date" />
        <van-cell title="付款人" :value="businessPayment.employee_name" />
        <van-cell title="操作时间" :value="businessPayment.created_at" />
      </div>
    </van-list>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getBusinessPayments } from '@/api/business-unpaid'

export default {
  components: { myNavBar },
  data() {
    return {
      loading: false,
      finished: false,
      showEmpty: false,
      list: [],
      listQuery: {
        page: 0
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getBusinessPayments(this.$route.query.business_id, this.listQuery).then(res => {
        this.loading = false
        this.finished = res.data.list.length < 10
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color.scss";
  .list {
    padding: 10px 10px 0 10px;
  }
  .item {
    padding-bottom: 10px;
  }
  .cell-value {
    color: $amountRedColor;
  }
</style>
