import request from '@/utils/request'

export function getBusinessUnpaids(params) {
  return request({
    method: 'get',
    url: 'business-unpaid',
    params
  })
}

export function getBusinessUnpaidDetail(id, params) {
  return request({
    method: 'get',
    url: `business-unpaid/${id}`,
    params
  })
}

export function payForBusiness(data) {
  return request({
    method: 'post',
    url: `business-unpaid`,
    data
  })
}

export function getBusinessPayments(id, params) {
  return request({
    method: 'get',
    url: `business-unpaid/${id}/payment`,
    params
  })
}
